import React from 'react'
import tw from 'twin.macro'
import Layout from '../../../sea-site-theme/src/components/Layout'
import SEO from '../../../sea-site-theme/src/components/SEO'
import { graphql } from 'gatsby'
import axios from 'axios'
import qs from 'qs'
import SupportSearch from '../components/contact-us/support-search'
import { StyledForm, HomeButtonRow, HomeButton } from '../../../sea-site-theme/src/components/form/styles'
import { Formik } from 'formik'
import { InputItems, initialValues, validationSchema } from '../../../sea-site-theme/src/components/form/utils'
import FormFieldGroup from '../../../sea-site-theme/src/components/form/FormFieldGroup'
import FormFieldSingle from '../../../sea-site-theme/src/components/form/FormFieldSingle'
import PopUp from '../../../sea-site-theme/src/components/modal/forms'

const ContentsSection = tw.section`w-full bg-gray-200 py-12`
const ContentsContainer = tw.div`md:w-11/12 bg-white py-10 px-4 md:px-10 mx-auto border border-gray-300 border-b rounded-lg`

const ContactContainer = tw.div`flex flex-col items-center justify-center`
const ContactTitle = tw.h3`font-display text-5xl md:text-6xl text-gray-700 font-bold text-center mb-3 md:mb-0`
const ContactDescription = tw.p`font-display text-lg mb-6 md:mb-12 text-gray-700 text-center`

type SupportEdges = {
	node: {
		seoTitle: string
		seoKeywords: string[] | undefined
		seoDescription: string | undefined
		node_locale: string
		slug: string
		body: {
			raw: string | undefined
		}
	}
}

type ContactEdges = {
	node: {
		title: string
		description: string | undefined
		endpoint: string
		items: InputItems
		node_locale: string
	}
}

interface PageData {
	data: {
		supportcontact: {
			edges: SupportEdges[]
		}
		contact: {
			edges: ContactEdges[]
		}
	}
	location: {
		search: string
	}
}

const Contact = ({ data: { supportcontact, contact }, ...props }: PageData): JSX.Element => {
	const ps = props.location.search
	const params = ps.length && ps[0] == '?' ? qs.parse(ps.replace(/^\?/, '')) : ''
	const [showPopUp, setShowPopUp] = React.useState(false)
	const openPopUp = () => setShowPopUp(true)
	const closePopUp = () => setShowPopUp(false)
	const [{ node }] = supportcontact.edges
	const { seoTitle, seoKeywords, seoDescription } = node

	const [{ node: nodeContact }] = contact.edges
	const { title: contactTitle, description: contactDescription, items: contactItems } = nodeContact
	return (
		<Layout>
			<SEO title={seoTitle} keywords={seoKeywords} description={seoDescription} />
			<SupportSearch />
			<ContentsSection id="contact-form">
				<ContentsContainer>
					<ContactContainer>
						<ContactTitle>{contactTitle}</ContactTitle>
						<ContactDescription>{contactDescription}</ContactDescription>
						<Formik
							initialValues={initialValues(contactItems, params?.subject)}
							validationSchema={validationSchema(contactItems)}
							onSubmit={async (values, { setSubmitting, resetForm }) => {
								try {
									setSubmitting(true)
									const result = await axios.post('/.netlify/functions/mail', {
										formName: 'contact-us',
										...values,
									})
									console.log(result)
									setSubmitting(false)
									resetForm()
									return openPopUp()
								} catch (error) {
									setSubmitting(false)
									return console.warn(error)
								}
							}}
						>
							{({ isSubmitting, errors, touched, values }): JSX.Element => {
								return (
									<StyledForm>
										{contactItems?.map((item) => {
											if (item.items) {
												const { title, items } = item
												return (
													<FormFieldGroup
														key={title}
														// color="#fff"
														{...{ items, errors, touched, values }}
													/>
												)
											} else {
												return (
													<FormFieldSingle
														key={item.inputName}
														overrideWidth="full"
														// color="#fff"
														{...{
															item: { ...item, helpText: null },
															errors,
															touched,
															values,
														}}
													/>
												)
											}
										})}
										<p>
											Maytronics may send updates and promotions from time to time. Please see our{' '}
											<a href="https://maytronics.com.au/privacy" target="_blank" rel="noopener noreferrer">
												privacy policy
											</a>{' '}
											for more details.
										</p>
										<HomeButtonRow>
											<HomeButton type="submit" disabled={isSubmitting}>
												Send Message
											</HomeButton>
										</HomeButtonRow>
									</StyledForm>
								)
							}}
						</Formik>
						<PopUp showDialog={showPopUp} close={closePopUp} />
					</ContactContainer>
				</ContentsContainer>
			</ContentsSection>
		</Layout>
	)
}

export const supportFaqQuery = graphql`
	query suppContactQuery {
		supportcontact: allContentfulPage(filter: { contentful_id: { eq: "2m8O4bbpRZm15Q97CtVGu" } }) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					body {
						raw
					}
				}
			}
		}
		contact: allContentfulForm(filter: { contentful_id: { eq: "6D1VD5gGdEys2GwRQKDpWf" } }) {
			edges {
				node {
					title
					description
					endpoint
					node_locale
					items {
						... on ContentfulFormField {
							inputName
							label
							inputId
							width
							inputRequired
							inputType
							helpText
							inputPlaceholder
							inputAutocomplete
							textareaRows
							textareaCols
							options
						}
						... on ContentfulFormGroup {
							title
							items {
								inputName
								label
								inputId
								width
								inputRequired
								inputType
								helpText
								inputPlaceholder
								inputAutocomplete
								textareaRows
								textareaCols
								options
							}
						}
					}
				}
			}
		}
	}
`

export default Contact
