import React from 'react'
import { SearchSection, SearchContainer, SearchHeading } from './styles'

const SupportSearch = (): JSX.Element => {
	return (
		<SearchSection>
			<SearchContainer>
				<SearchHeading>Hi. How can we help?</SearchHeading>
			</SearchContainer>
		</SearchSection>
	)
}

export default SupportSearch
