import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Link } from 'gatsby'
// import { AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion'

// export const FeatureName = styled(AccordionButton)`
// 	${tw`relative py-6 md:py-8 pr-6 cursor-pointer overflow-hidden font-display uppercase relative text-left font-bold`}

// 	> svg {
// 		${tw`absolute right-0 cursor-pointer`}
// 		transition: all 0.3s linear;
// 		top: 25px;
// 		bottom: 0;
// 	}
// `

// export const FeatureDescription = styled(AccordionPanel)`
// 	${tw`font-display text-gray-700 mb-4`}
// `
// export const AccItem = styled(AccordionItem)`
// 	${tw`border-b-2 border-gray-300`}
// `

export const DescriptionContainer = tw.div`flex flex-col bg-white px-4 md:px-8 py-6 border border-gray-300`

// export const AccItemInner = styled(AccItem)``
// export const AccItemInnerName = styled(FeatureName)`
// 	${tw`font-normal`}
// `
export const AccItemInnerDescContainer = tw.div`flex flex-col bg-gray-300 w-95 mx-auto px-8 py-6`

export const Desc = tw.p`font-display mb-1`
export const ReadMore = styled(Link)`
	${tw`font-display underline text-gray-900 hover:no-underline hover:text-gray-600`}
`
export const SearchSection = styled.section`
	height: 200px;
	max-height: 200px;

	@media (max-width: 767px) {
		height: 150px;
		max-height: 150px;
	}
	background: linear-gradient(to right, #1b7db3 0%, #2e8fb1 25%, #58c8df 70%, #83d2e4 100%);
`
export const SearchContainer = styled.div`
	${tw`flex flex-col w-full items-center justify-center h-full px-6`}
`
export const SearchHeading = styled.h2`
	${tw`text-4xl md:text-5xl text-white font-bold font-display text-center`}
`
export const TabsSection = tw.section`w-full bg-gray-200 pt-8`
export const TabsContainer = tw.div`w-95 md:w-11/12 mx-auto`
export const TabsList = tw.ul`flex flex-row`
export const TabsItem = styled.li`
	${tw`w-1/5 font-display text-center text-lg font-bold cursor-pointer border-r border-gray-300 border-t border-b border-gray-300`}

	&:first-of-type {
		${tw`border-l border-gray-300`}
	}

	> a > p {
		@media (max-width: 524px) {
			font-size: 11px;
		}
		@media (max-width: 374px) {
			font-size: 10px;
		}
		${tw`mt-2 md:mt-4 text-xs md:text-base`}
	}

	&.active {
		${tw`border-b-0 bg-white`}
	}

	&.active > a {
		${tw`border-t-4 border-blue-500`}
	}

	&.active > a > p {
		${tw`text-blue-500`}
	}

	&.active > a svg {
		${tw`text-blue-500`}
	}

	&.notactive > a {
		${tw`relative`}
	}
	&.notactive > a::before {
		content: '';
		${tw`absolute bg-blue-500 mx-auto top-0 left-0 right-0 w-0`}
		height: 4px;
		-webkit-transition: 0.3s ease;
		-moz-transition: 0.3s ease;
		-ms-transition: 0.3s ease;
		-o-transition: 0.3s ease;
		transition: 0.3s ease;
	}
	&.notactive > a:hover:before {
		${tw`w-full`}
	}
	&.notactive:hover {
		${tw`bg-gray-100`}
	}
`
export const TabsLink = styled(Link)`
	${tw`w-full h-full py-3 px-4 text-center flex flex-col items-center`}
`
